import { Dayjs } from 'dayjs'

import { dayjs } from '../../../lib/dayjs'

export class DateTime {
  static currentLocale = dayjs.locale()

  static justNowMessages: Record<string, string> = {
    ja: 'たった今',
    en: 'just now',
  }

  /**
   * Format date data to readably date text.
   * @param date
   */
  static formatRelative(date: Date | number) {
    return dayjs(date).fromNow()
  }

  /**
   * 秒単位の差分の表記に修正を加えた
   * 相対時間を表示する関数
   *
   * 通信等に起因するフロントと DB のミリ秒単位の時刻ズレにより
   * 未来と判定され「数秒後」と表示されることを回避する際に用いる
   * ref. https://app.clickup.com/t/908127/UT-17729
   */
  static formatRelativeSecondAdjust(date: Date | number) {
    const diffInSeconds = Math.abs(dayjs().diff(date, 'second'))

    if (diffInSeconds > 60) return this.formatRelative(date)

    return this.justNowMessages[this.currentLocale] || 'just now'
  }

  static format(date: Date | null | number | string) {
    return date && dayjs(date).format('YYYY/MM/DD HH:mm')
  }

  static formatStartEndTime(
    date: Date | null | number | string,
    minute?: number | null
  ) {
    if (!minute) {
      return date && dayjs(date).format('YYYY/MM/DD HH:mm')
    }
    const startDate = dayjs(date)
    const endDate = dayjs(date).add(minute, 'minute')

    const startDateTemplate =
      startDate.startOf('year').unix() === dayjs().startOf('year').unix()
        ? 'MM/DD HH:mm'
        : 'YYYY/MM/DD HH:mm'

    const endDateTemple =
      endDate.startOf('year').unix() === startDate.startOf('year').unix()
        ? endDate.startOf('day').unix() === startDate.startOf('day').unix()
          ? 'HH:mm'
          : 'MM/DD HH:mm'
        : 'YYYY/MM/DD HH:mm'

    return `${startDate.format(startDateTemplate)}〜${endDate.format(
      endDateTemple
    )}`
  }

  static formatDate(
    date: Date | null | number | string | Dayjs,
    template = 'YYYY/MM/DD'
  ) {
    return date && dayjs(date).format(template)
  }

  static now() {
    return dayjs().format('YYYY/MM/DD HH:mm')
  }

  static nowUnixMilliseconds() {
    return dayjs().valueOf()
  }

  static toDate(date: string) {
    return dayjs(date, 'YYYY/MM/DD HH:mm').toDate()
  }

  static durationDate(
    startDate: Date | null | number,
    endDate: Date | null | number
  ) {
    return dayjs.duration(dayjs(endDate).diff(dayjs(startDate)))
  }

  static diff(
    date: Date | null | number | string,
    diff: 'month' | 'day' | 'hour' | 'minute',
    float = false
  ) {
    return date && dayjs().diff(date, diff, float)
  }

  static isBetween(
    startDate: Date | null | number,
    endDate: Date | null | number,
    targetDate?: Date | null | number
  ) {
    if (!startDate || !endDate) return false
    const target = dayjs(targetDate).valueOf()
    return (
      dayjs(startDate).valueOf() <= target && dayjs(endDate).valueOf() > target
    )
  }

  static isGt(
    baseDate: Date | null | number,
    targetDate?: Date | null | number
  ) {
    if (!baseDate) return false
    const target = dayjs(targetDate).valueOf()
    return dayjs(baseDate).valueOf() > target
  }

  static isLt(
    baseDate: Date | null | number,
    targetDate?: Date | null | number
  ) {
    if (!baseDate) return false
    const target = dayjs(targetDate).valueOf()
    return dayjs(baseDate).valueOf() < target
  }
}
