import { generateAdapter } from './generateAdapter'

/**
 * authAdapterからAuth Hookを生成する。
 */
export function makeAuthHook() {
  const { AuthProvider, useAuth, useCurrentUser, getUser, getAccessToken } =
    generateAdapter()
  return { AuthProvider, useAuth, useCurrentUser, getUser, getAccessToken }
}
