import {
  checkIntercomAvailable,
  identifyIntercom,
  pageIntercom,
  trackIntercom,
} from '../../../intercom'
import { IdentifyArg, TrackArg } from '../plugin.interface'

export default process.browser
  ? function Browser(pluginConfig = {}) {
      return {
        NAMESPACE: 'intercom',
        config: pluginConfig,
        // NOTE: 初期化は各画面で行う
        initialize: () => {},
        page: pageIntercom,
        track: ({ payload: { event, properties } }: TrackArg<{}>) => {
          trackIntercom(event, properties)
        },
        identify: ({ payload: { userId, traits } }: IdentifyArg<{}>) => {
          identifyIntercom({
            avatar: traits?.photoURL,
            user_id: userId,
            email: traits?.email,
            name: traits?.displayName,
            createdAt: traits?.metadata?.creationTime,
            artist: traits?.claims?.artist,
          })
        },
        loaded: checkIntercomAvailable,
      }
    }
  : function Node(config = {}) {
      return {
        NAMESPACE: 'intercom',
        config,
        page: () => {},
        track: () => {},
        identity: () => {},
      }
    }
