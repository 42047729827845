"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Snackbar = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var material_1 = require("@mui/material");
var components_1 = require("../../../components");
/**
 * スナックバー（主にメッセージ表示にて使用）
 *
 * @param open スナックバー表示状態
 * @param onClose Closeアイコンを押した時のイベント
 * @param message バーの表示文言
 * @param severity メッセージ重要度（色・アイコンが変わる） default | info | success | warning | error
 * @param autoHide true(デフォルト)の場合、表示6秒後に自動で消える。falseの場合、クローズイベントを起こすまで消えない。
 * @param action
 * @param secondaryAction 右端に表示されるアクション
 * @param secondaryMessage バーの表示文言
 */
var Snackbar = function (_a) {
    var open = _a.open, onClose = _a.onClose, message = _a.message, _b = _a.severity, severity = _b === void 0 ? 'default' : _b, _c = _a.autoHide, autoHide = _c === void 0 ? true : _c, action = _a.action, secondaryAction = _a.secondaryAction, secondaryMessage = _a.secondaryMessage;
    return (jsx_runtime_1.jsx(material_1.Snackbar, __assign({ open: open, anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
        }, autoHideDuration: autoHide ? 6000 : null, onClose: onClose, sx: function (theme) {
            var _a;
            return (__assign(__assign({}, (secondaryAction
                ? {
                    '.MuiAlert-icon': {
                        display: 'none',
                    },
                }
                : {})), (_a = {}, _a[theme.breakpoints.up('sm')] = {
                width: '100%',
                maxWidth: 600,
            }, _a)));
        } }, { children: severity === 'default' ? (jsx_runtime_1.jsx(material_1.SnackbarContent, { message: message, action: autoHide ? null : (jsx_runtime_1.jsxs(material_1.Stack, __assign({ alignItems: "center", direction: "row" }, { children: [secondaryAction, jsx_runtime_1.jsx(components_1.IconButton.Close, { onClick: onClose, sx: { backgroundColor: 'inherit' } }, void 0)] }), void 0)) }, void 0)) : (jsx_runtime_1.jsxs(material_1.Alert
        // severity: success時、カラーはinfoのものを適用する
        , __assign({ 
            // severity: success時、カラーはinfoのものを適用する
            color: severity === 'success' ? 'info' : undefined, variant: "filled", onClose: autoHide ? undefined : onClose, severity: severity, action: action, sx: {
                padding: '6px 16px',
                width: '100%',
                mx: 5,
                '.MuiAlert-message': {
                    width: '100%',
                },
                '.MuiAlert-icon': {
                    alignItems: 'center',
                },
                '.MuiSvgIcon-root': {
                    color: 'inherit',
                },
            } }, { children: [jsx_runtime_1.jsxs(material_1.Stack, __assign({ alignItems: "center", direction: "row", justifyContent: "space-between" }, { children: [message, secondaryAction] }), void 0), secondaryMessage && (jsx_runtime_1.jsx(material_1.Stack, __assign({ alignItems: "flex-end" }, { children: secondaryMessage }), void 0))] }), void 0)) }), void 0));
};
exports.Snackbar = Snackbar;
