import { loadStripe } from '@stripe/stripe-js'
import axios from 'axios'

import { config } from '@/config'

export const api = axios.create({
  baseURL: 'https://api.stripe.com/v1/',
})

type CreditCard = {
  number: string
  name: string
  cvc: string
  expMonth: string
  expYear: string
}
function createTokenWithCard({
  number,
  name,
  cvc,
  expMonth,
  expYear,
}: CreditCard) {
  const query = new URLSearchParams()
  query.append('card[number]', number)
  query.append('card[name]', name)
  query.append('card[cvc]', cvc)
  query.append('card[exp_month]', expMonth)
  query.append('card[exp_year]', expYear)

  return api
    .post('/tokens', query)
    .then(res => {
      return res.data.id
    })
    .catch(() => {
      throw new Error('createTokenWithCardError')
    })
}

const confirmCardSetupWithStripe = async (clientSecret: string) => {
  const stripe = await loadStripe(config.stripeApiKey)
  if (!stripe) throw new Error('Stripe failed to initialize')

  return stripe.confirmCardSetup(clientSecret)
}

export { confirmCardSetupWithStripe, createTokenWithCard }
