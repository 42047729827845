import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import * as formEn from '@/core/form/locales/en'
import * as formJa from '@/core/form/locales/ja'
import * as en from '@/core/i18n/locales/en'
import * as ja from '@/core/i18n/locales/ja'

import commonEn from '../../../apps/current/locales/en/common.json'
import commonJa from '../../../apps/current/locales/ja/common.json'

export async function init() {
  i18n.use(initReactI18next).init({
    lng: 'ja',
    fallbackLng: 'ja',
    resources: {
      ja: { ...ja, ...formJa },
      en: { ...en, ...formEn },
    },
    returnObjects: true,
    defaultNS: 'common',
    fallbackNS: 'common',
    interpolation: {
      format(value, format) {
        if (format === 'uppercase') return value?.toUpperCase()
        if (format === 'lowercase') return value?.toLowerCase()
        return value
      },
      escapeValue: false,
    },
  })

  /**
   * 各アプリのcommon.jsonをパースしてi18nの値を上書きする
   */
  const i18nResourcesJa = { common: {} }
  const i18nResourcesEn = { common: {} }
  Object.entries(commonJa).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      i18n.addResourceBundle('ja', key, value, true, true)
    } else {
      i18nResourcesJa['common'] = {
        ...i18nResourcesJa['common'],
        [key]: value,
      }
    }
  })
  Object.entries(commonEn).forEach(([key, value]) => {
    if (typeof value === 'object' && value !== null) {
      i18n.addResourceBundle('en', key, value, true, true)
    } else {
      i18nResourcesEn['common'] = {
        ...i18nResourcesEn['common'],
        [key]: value,
      }
    }
  })
  i18n.addResourceBundle('ja', 'common', i18nResourcesJa['common'], true, true)
  i18n.addResourceBundle('en', 'common', i18nResourcesEn['common'], true, true)

  /**
   * デフォルト言語を日本語に設定
   */
  i18n.changeLanguage('ja')
}

export { i18n }
