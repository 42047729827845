import {
  FirebaseAuthentication,
  ProviderId,
} from '@capacitor-firebase/authentication'
import {
  confirmPasswordReset,
  EmailAuthProvider,
  getAdditionalUserInfo,
  getRedirectResult,
  IdTokenResult,
  linkWithCredential,
  OAuthProvider,
  onAuthStateChanged,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInAnonymously,
  signInWithCredential,
  signInWithEmailAndPassword,
  signOut,
  TwitterAuthProvider,
  unlink,
  updateEmail,
  updatePassword,
  User as FBUser,
  verifyPasswordResetCode,
} from 'firebase/auth'
import get from 'lodash/get'
import { createContext, useContext, useEffect, useState } from 'react'

import { AuthAdapterFn, messageRef, User, UserClaims } from '@/core'
import { getPlatform } from '@/core/native/app'
import { getAuth } from '@/lib/firebase'

const AuthContext = createContext<ReturnType<typeof useAuthUser>>({
  loading: false,
  user: null,
  error: null,
  setUser: () => null,
  userClaims: null,
  setUserClaims: () => null,
})

export const adaptFirebaseAuth: AuthAdapterFn<{}, User | null> = () => {
  const useCurrentUser = () => useContext(AuthContext)

  return {
    useAuth() {
      const { user, loading, setUser, userClaims, setUserClaims } =
        useCurrentUser()

      return {
        user,
        isAuthenticated: !!user,
        loading,
        userClaims,
        // SNS認証のみによるアカウントかどうか
        isBoundWithSNS:
          !!user?.providers &&
          user?.providers.length === 1 &&
          (user?.providers[0].providerId === ProviderId.TWITTER ||
            user?.providers[0].providerId === 'apple'),
        loginWithEmail: (email, password, callback) => {
          return signInWithEmailAndPassword(getAuth(), email, password).then(
            async ({ user }) => {
              if (callback) {
                await callback()
              }
              const transformedUser = transformUser(user)
              setUser(transformedUser)
              return transformedUser
            }
          )
        },
        getUserClaims: async () => {
          const transformedUserClaims = await getUserClaims()
          setUserClaims(transformedUserClaims)
          return transformedUserClaims
        },
        logout: async () => {
          await signOut(getAuth())
          setUserClaims(null)
          setUser(null)
        },
        reloadUser: async () => {
          const auth = getAuth()
          await auth.currentUser?.reload()
          const user = auth.currentUser
          setUser(transformUser(user))
        },
        signInAnonymously: async () => {
          const auth = getAuth()
          return signInAnonymously(auth).then(({ user }) => {
            const transformedUser = transformUser(user)
            setUser(transformedUser)
            return transformedUser
          })
        },
        signInWithApple: async callback => {
          /**
           * NOTE: CapacitorのOAuthログインメソッドでは、credentialだけ取ってくることができないため、匿名ログインユーザーと本ログインユーザーを連結することができない。
           * そのため、カート機能においては、本ログイン時にAPIで匿名ユーザーを削除し、擬似的に連結している。
           * https://app.clickup.com/t/860t2bcy2
           */
          // 1. Create credentials on the native layer
          const result = await FirebaseAuthentication.signInWithApple({
            skipNativeAuth: true,
          })
          // 2. Sign in on the web layer using the id token and nonce
          const provider = new OAuthProvider(ProviderId.APPLE)
          if (getPlatform() === 'ios') {
            provider.addScope('email')
            provider.addScope('name')
          }
          const credential = provider.credential({
            idToken: result.credential?.idToken,
            rawNonce: result.credential?.nonce,
          })
          const auth = getAuth()
          const { user } = await signInWithCredential(auth, credential)
          if (callback) {
            await callback()
          }
          // @ts-ignore
          const transformedUser = transformUser(user)
          setUser(transformedUser)
        },
        signInWithTwitter: async callback => {
          /**
           * NOTE: CapacitorのOAuthログインメソッドでは、credentialだけ取ってくることができないため、匿名ログインユーザーと本ログインユーザーを連結することができない。
           * そのため、カート機能においては、本ログイン時にAPIで匿名ユーザーを削除し、擬似的に連結している。
           * https://app.clickup.com/t/860t2bcy2
           */
          // 1. Create credentials on the native layer
          const result = await FirebaseAuthentication.signInWithTwitter({
            skipNativeAuth: false,
          })
          // 2. Sign in on the web layer using the access token and secret
          const credential = TwitterAuthProvider.credential(
            result.credential?.accessToken || '',
            result.credential?.secret || ''
          )
          const auth = getAuth()
          const { user } = await signInWithCredential(auth, credential)
          if (callback) {
            await callback()
          }
          // @ts-ignore
          const transformedUser = transformUser({
            ...user,
            displayName:
              user?.displayName ??
              (result.additionalUserInfo?.profile?.name as string) ??
              '',
            email: result.additionalUserInfo?.profile?.email as string,
            photoURL: user?.photoURL?.replace('_normal', '') ?? '',
          })
          setUser(transformedUser)
          return {
            providerId: 'twitter',
            user: transformedUser,
            credential: {
              oauthToken: credential?.accessToken,
              oauthTokenSecret: credential?.secret,
            },
          }
        },
        linkEmail: async (email, password) => {
          const credential = EmailAuthProvider.credential(email, password)

          const user = await getUser()

          if (!user) throw new Error('user not found')

          await reauthenticateBySocialLogin()
          return linkWithCredential(user, credential).then(({ user }) => {
            const transformedUser = transformUser(user)
            setUser(transformedUser)
          })
        },
        unlinkEmail: async () => {
          const { providerId } = new EmailAuthProvider()

          const user = await getUser()

          if (!user) throw new Error('user not found')

          return unlink(user, providerId).then(user => {
            const transformedUser = transformUser(user)
            setUser(transformedUser)
          })
        },
        linkTwitter: async () => {
          const user = await getUser()
          if (!user) throw new Error('user not found')
          const res = await FirebaseAuthentication.linkWithTwitter({
            skipNativeAuth: false,
          })
          // credentialが取得できない場合エラー
          if (!res?.credential) throw new Error('authenticationFailed')
          const transformedUser = transformUser(await getUser())
          setUser(transformedUser)
          const { additionalUserInfo: info, credential } = res
          return {
            providerId: 'twitter',
            user: transformedUser,
            credential: {
              oauthToken: credential?.accessToken,
              oauthTokenSecret: credential?.secret,
            },
            additionalUserInfo: {
              accountId: (info?.profile?.screen_name as string)?.toLowerCase(),
              displayName: info?.profile?.name as string,
              introduction: info?.profile?.description as string,
              profileUrl: info?.profile?.profile_image_url_https as string,
            },
          }
        },
        unLinkTwitter: async () => {
          const { providerId } = new TwitterAuthProvider()
          const user = await getUser()

          if (!user) throw new Error('user not found')

          return unlink(user, providerId).then(user => transformUser(user))
        },
        updateEmail: async (newEmail: string, password: string) => {
          const firebaseUser = getAuth().currentUser
          if (!firebaseUser) return

          return reauthenticate(firebaseUser.email || '', password)
            .then(async () => await updateEmail(firebaseUser, newEmail))
            .then(() => {
              if (user) {
                setUser({
                  ...user,
                  email: newEmail,
                })
              }
            })
            .catch(error => {
              throw error
            })
        },
        updateEmailSocial: async (email: string) => {
          const firebaseUser = getAuth().currentUser
          if (!firebaseUser) return

          return updateEmail(firebaseUser, email)
            .then(() => {
              if (user) {
                setUser({ ...user, email })
              }
            })
            .catch(error => {
              throw error
            })
        },
        updatePassword: async (oldPassword, newPassword) => {
          const firebaseUser = getAuth().currentUser
          if (!firebaseUser) return

          return reauthenticate(firebaseUser.email || '', oldPassword)
            .then(async () => await updatePassword(firebaseUser, newPassword))
            .catch(error => {
              throw error
            })
        },
        sendPasswordResetEmail: async (email: string) => {
          return sendPasswordResetEmail(getAuth(), email)
        },
        verifyPasswordResetCode: async (code: string) => {
          return verifyPasswordResetCode(getAuth(), code)
        },
        confirmPasswordReset: async (code: string, newPassword: string) => {
          return confirmPasswordReset(getAuth(), code, newPassword)
        },
        getRedirectResult: () => {
          return getRedirectResult(getAuth()).then(res => {
            // redirect operationではない場合userはnullなので、なにもしない
            if (!res?.user) return null

            const info = getAdditionalUserInfo(res)

            if (res.providerId === ProviderId.TWITTER) {
              const credential = TwitterAuthProvider.credentialFromResult(res)
              // credentialが取得できない場合エラー
              if (!credential) throw new Error('authenticationFailed')

              return {
                providerId: 'twitter',
                user: transformUser(res.user),
                credential: {
                  oauthToken: credential?.accessToken,
                  oauthTokenSecret: credential?.secret,
                },
                additionalUserInfo: {
                  accountId: (
                    info?.profile?.screen_name as string
                  )?.toLowerCase(),
                  displayName: info?.profile?.name as string,
                  introduction: info?.profile?.description as string,
                  profileUrl: info?.profile?.profile_image_url_https as string,
                },
              }
            }

            if (res.providerId === ProviderId.APPLE) {
              if (!info?.isNewUser) return null

              return {
                providerId: 'apple',
                user: transformUser(res.user),
                credential: null,
                additionalUserInfo: {
                  accountId: res.user.uid.toLowerCase().slice(20),
                  displayName:
                    res.user.displayName ||
                    res.user.uid.toLowerCase().slice(20),
                  introduction: '',
                  profileUrl: '',
                },
              }
            }

            // 連携対象のプロバイダーが見つからない場合エラー
            throw new Error('no provider.')
          })
        },
      }
    },
    AuthProvider: ({ children }) => {
      const userInfo = useAuthUser()
      return (
        <AuthContext.Provider value={userInfo}>{children}</AuthContext.Provider>
      )
    },

    useCurrentUser: () => useContext(AuthContext),

    async getUser() {
      return getUser().then(user => transformUser(user))
    },
    async getAccessToken(opt) {
      return getUser(false)
        .then(user => user?.getIdToken(!!opt?.forceUpdateToken) || null)
        .catch(error => {
          messageRef.current?.showError(error)
          return null
        })
    },
  }
}

const useAuthUser = () => {
  const [user, setUser] = useState<User | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const [error, setError] = useState<Error | null>(null)
  const [userClaims, setUserClaims] = useState<UserClaims | null>(null)

  useEffect(() => {
    setLoading(true)
    getUser()
      .then(user => {
        setUser(transformUser(user))
        return getUserClaims()
      })
      .then(newUserClaims => {
        setUserClaims(newUserClaims)
      })
      .catch(e => setError(e))
      .finally(() => setLoading(false))
  }, [])

  return { loading, user, error, setUser, userClaims, setUserClaims }
}

/**
 * Userデータをfirebaseから一般型に変更
 * @param user
 * @returns
 */
function transformUser(user: FBUser | null): User | null {
  if (!user) return null

  // Prioritize retrieving the latest data from reloadUserInfo first, then fallback to providerData
  const providers = (get(user, 'reloadUserInfo.providerUserInfo') ||
    user.providerData) as FBUser['providerData']

  return {
    uid: user.uid,
    email: user.email,
    emailVerified: user.emailVerified,
    displayName: user.displayName || '',
    avatarUrl: user.photoURL,
    phoneNumber: user.phoneNumber,
    providers: providers?.map(providerData => {
      return {
        displayName: providerData?.displayName || null,
        providerId: providerData?.providerId || null,
      }
    }),
    introduction: '',
    scope: {},
    roles: [],
    // 匿名ユーザーとしてログインしている場合はtrue
    isAnonymous: !!user?.isAnonymous,
    // 本ユーザーとしてログインしている場合はtrue
    isSigned: !!user && !user?.isAnonymous,
  }
}

function transformUserClaims(tokenResult?: IdTokenResult): UserClaims | null {
  if (!tokenResult?.claims) {
    return null
  }
  return {
    orgs: tokenResult?.claims?.orgs as
      | Record<string, { artist: boolean }>
      | undefined,
  }
}

function getUser(forceReloadUser = true) {
  return new Promise<FBUser | null>((res, rej) => {
    const unsubscribe = onAuthStateChanged(
      getAuth(),
      user => {
        unsubscribe()
        if (!user) return res(null)

        if (forceReloadUser) {
          user
            .reload()
            .then(() => res(user))
            .catch(rej)
        } else {
          res(user)
        }
      },
      rej
    )
  })
}

async function getUserClaims(): Promise<UserClaims | null> {
  const currentUser = await getAuth().currentUser
  if (!currentUser) {
    return Promise.resolve(null)
  }
  const idTokenResult = await currentUser.getIdTokenResult()
  return transformUserClaims(idTokenResult)
}

async function reauthenticate(email: string, password: string) {
  const user = getAuth().currentUser
  if (!user) return

  const credentials = EmailAuthProvider.credential(email, password)
  return reauthenticateWithCredential(user, credentials)
}

async function reauthenticateBySocialLogin() {
  const user = getAuth().currentUser
  if (!user) return

  const userProviders = user.providerData?.map(provider => provider?.providerId)

  if (userProviders.some(providerId => providerId === ProviderId.TWITTER)) {
    return reauthenticateByTwitter()
  }

  if (userProviders.some(providerId => providerId === ProviderId.APPLE)) {
    return reauthenticateByApple()
  }
}

async function reauthenticateByTwitter() {
  const user = getAuth().currentUser
  if (!user) return

  const result = await FirebaseAuthentication.signInWithTwitter({
    skipNativeAuth: false,
  })

  const credential = TwitterAuthProvider.credential(
    result.credential?.accessToken || '',
    result.credential?.secret || ''
  )

  return reauthenticateWithCredential(user, credential)
}

async function reauthenticateByApple() {
  const user = getAuth().currentUser
  if (!user) return

  const result = await FirebaseAuthentication.signInWithApple({
    skipNativeAuth: true,
  })

  const provider = new OAuthProvider(ProviderId.APPLE)
  if (getPlatform() === 'ios') {
    provider.addScope('email')
    provider.addScope('name')
  }
  const credential = provider.credential({
    idToken: result.credential?.idToken,
    rawNonce: result.credential?.nonce,
  })

  return reauthenticateWithCredential(user, credential)
}
