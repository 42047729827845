import { Preferences } from '@capacitor/preferences'

import {
  defaultValues as inputBusinessDefaultValues,
  StorageSaveValues,
} from '@/components/pages/ConsoleBusinessEdit/template/ConsoleBusinessEditForm'
import { SupportedLanguage } from '@/types/config'

export function setRedirectAfterLoginPath(item: string) {
  return Preferences.set({ key: 'redirectAfterLogin', value: item })
}

export async function getInputPostOptions(): Promise<{ withTwitter: boolean }> {
  try {
    const { value } = await Preferences.get({ key: 'inputPostOptions' })
    return JSON.parse(value ?? '')
  } catch (e) {
    return {
      withTwitter: false,
    }
  }
}

export function setInputPostOptions(item: { withTwitter: boolean }) {
  return Preferences.set({
    key: 'inputPostOptions',
    value: JSON.stringify(item),
  })
}

export function getRedirectAfterLoginPath() {
  return Preferences.get({ key: 'redirectAfterLogin' })
}

export function setInputSignUpArtist(item: {
  displayName: string
  accountId: string
  email: string
  password: string
  invitationCode: string
}) {
  return Preferences.set({
    key: 'inputSignUpArtist',
    value: JSON.stringify(item),
  })
}

export async function getInputSignUpArtist(): Promise<{
  displayName: string
  accountId: string
  email: string
  password: string
  invitationCode: string
}> {
  try {
    const { value } = await Preferences.get({ key: 'inputSignUpArtist' })
    return JSON.parse(value ?? '')
  } catch (e) {
    return {
      displayName: '',
      accountId: '',
      email: '',
      password: '',
      invitationCode: '',
    }
  }
}

export function removeInputSignUpArtist() {
  return Preferences.remove({ key: 'inputSignUpArtist' })
}

export function setInputBusiness(item: StorageSaveValues) {
  return Preferences.set({
    key: 'inputBusiness',
    value: JSON.stringify(item),
  })
}

export async function getInputBusiness(): Promise<StorageSaveValues> {
  try {
    const { value } = await Preferences.get({ key: 'inputBusiness' })
    return JSON.parse(value ?? '')
  } catch (e) {
    return inputBusinessDefaultValues as StorageSaveValues
  }
}

export function removeInputBusiness() {
  return Preferences.remove({ key: 'inputBusiness' })
}

export function setMailInput(email: string) {
  return Preferences.set({ key: 'mailInput', value: email })
}

export async function getMailInput() {
  return Preferences.get({ key: 'mailInput' })
}

export function removeMailInput() {
  return Preferences.remove({ key: 'mailInput' })
}

export function clearStorage() {
  return Preferences.clear()
}

export function setCredential(credential: {
  providerId: string
  oauthToken: string
  oauthTokenSecret: string
}) {
  Preferences.set({
    key: 'credential',
    value: JSON.stringify(credential),
  })
}

export async function getCredential() {
  const { value } = await Preferences.get({ key: 'credential' })
  return JSON.parse(value ?? '{}')
}

export function removeCredential() {
  Preferences.remove({ key: 'credential' })
}

export function setAcceptCommunityNote(value: string) {
  return Preferences.set({ key: 'acceptCommunityNote', value })
}

export async function getAcceptCommunityNote() {
  return Preferences.get({ key: 'acceptCommunityNote' })
}

export function setAppLanguage(value: string) {
  return Preferences.set({ key: 'lang', value })
}

export async function getAppLanguage(): Promise<SupportedLanguage | null> {
  const { value } = await Preferences.get({ key: 'lang' })
  return value as SupportedLanguage
}

type LiveStreamSetting = {
  facingMode: 'user' | 'environment'
  showVideo: boolean
  showComment: boolean
  playAudio: boolean
}

export function setLiveStreamSetting(value: LiveStreamSetting) {
  return Preferences.set({
    key: 'liveStreamSetting',
    value: JSON.stringify(value),
  })
}

export async function getLiveStreamSetting(): Promise<LiveStreamSetting> {
  const { value } = await Preferences.get({ key: 'liveStreamSetting' })
  return JSON.parse(value ?? '{}')
}

export async function removeLiveStreamSetting() {
  return Preferences.remove({ key: 'liveStreamSetting' })
}

export async function setChunkFileErrors(value: string[]) {
  return Preferences.set({
    key: 'chunkFileErrors',
    value: JSON.stringify(value),
  })
}

export async function getChunkFileErrors(): Promise<string[]> {
  const { value } = await Preferences.get({ key: 'chunkFileErrors' })
  return JSON.parse(value ?? '[]')
}

type MagazineHistory = {
  itemId: string
  activeIndex: number
  numPages: number
  open: boolean
}

export function setMagazineHistory(value: MagazineHistory) {
  return Preferences.set({
    key: 'magazineHistory',
    value: JSON.stringify(value),
  })
}

/** ストア決済時に使用する情報を保存する */
type StoreProceed = {
  /* 氏名。コンビニ決済選択時の入力値 */
  fullName?: string
  /* 購入枚数 */
  quantity?: number
  /* 購入種別 */
  purchaseType?: 'cash' | 'konbini' | 'prepaid_point'
  /* SKU */
  skuId?: string
  /** 受け取り時間 */
  pickupTimes?: { collectionId: string; pickupTime: string | null }[] | null
}

export async function getStoreProceed(): Promise<StoreProceed> {
  const { value } = await Preferences.get({ key: 'storeProceed' })
  return JSON.parse(value ?? '{}')
}

export async function setStoreProceed(value: StoreProceed) {
  return Preferences.set({
    key: 'storeProceed',
    value: JSON.stringify(value),
  })
}

export async function getMagazineHistory(): Promise<MagazineHistory> {
  const { value } = await Preferences.get({ key: 'magazineHistory' })
  return JSON.parse(value ?? '{}')
}

export async function setResaleApplicationInput(value: {
  itemCount: string
  itemId: string
}) {
  return Preferences.set({
    key: 'resaleApplicationInput',
    value: JSON.stringify(value),
  })
}

export async function removeStoreProceed() {
  return Preferences.remove({ key: 'storeProceed' })
}

type AnonymousUser = {
  uid: string
  displayName: string
}

export async function setAnonymousUser(value: AnonymousUser) {
  return Preferences.set({
    key: 'anonymousUser',
    value: JSON.stringify(value),
  })
}

export async function getResaleApplicationInput(): Promise<{
  itemCount: string
  itemId: string
}> {
  const { value } = await Preferences.get({ key: 'resaleApplicationInput' })
  return JSON.parse(value ?? '{}')
}

export async function removeResaleApplicationInput() {
  return Preferences.remove({ key: 'resaleApplicationInput' })
}

export async function getAnonymousUser(): Promise<AnonymousUser> {
  const { value } = await Preferences.get({ key: 'anonymousUser' })
  return JSON.parse(value ?? '{}')
}

export async function removeAnonymousUser() {
  return Preferences.remove({ key: 'anonymousUser' })
}

export async function setRegisterNewMembership() {
  return Preferences.set({
    key: 'registerNewMembership',
    value: 'true',
  })
}

export async function getRegisterNewMembership(): Promise<boolean> {
  const { value } = await Preferences.get({ key: 'registerNewMembership' })
  return value === 'true'
}

export async function removeRegisterNewMembership() {
  return Preferences.remove({ key: 'registerNewMembership' })
}

// ユーザーの個人情報入力フォームへのリダイレクトフラグを管理する
type UserInfoRegisterRedirect = {
  /* 登録が終わった後の遷移先 */
  direction: string
  /** 電番の登録が必要な場合true */
  phoneNumber: boolean
  /** 住所の登録が必要な場合true */
  address: boolean
}

export async function setUserInfoRegisterRedirect(
  value: UserInfoRegisterRedirect
) {
  return Preferences.set({
    key: 'userInfoRegisterRedirect',
    value: JSON.stringify(value),
  })
}

export async function getUserInfoRegisterRedirect(): Promise<UserInfoRegisterRedirect> {
  const { value } = await Preferences.get({ key: 'userInfoRegisterRedirect' })
  return JSON.parse(value ?? '{}')
}

export async function removeUserInfoRegisterRedirect() {
  return Preferences.remove({ key: 'userInfoRegisterRedirect' })
}

export function setRedirectAfterCreditCardRegisterPath(item: string) {
  return Preferences.set({
    key: 'redirectAfterCreditCardRegister',
    value: item,
  })
}

export function getRedirectAfterCreditCardRegisterPath() {
  return Preferences.get({ key: 'redirectAfterCreditCardRegister' })
}

export function removeRedirectAfterCreditCardRegisterPath() {
  return Preferences.remove({ key: 'redirectAfterCreditCardRegister' })
}

// =========================================
//            ストレージ汎用メソッド
// =========================================

// ストレージのキー
export type PreferenceKey =
  | 'preSaleLotteryApplicationInput'
  | 'currentPreSaleId'
  | 'rootAuthInfo'
  | 'artistMembershipPurchaseInput'

/**
 * 内部ストレージに保存する
 * @param key PreferenceKey
 * @param value 値
 */
export async function setPreference(
  key: PreferenceKey,
  value: string | object
) {
  if (value instanceof Object) {
    return Preferences.set({ key, value: JSON.stringify(value) })
  }
  return Preferences.set({ key, value })
}

/**
 * 内部ストレージから取得する（JSONオブジェクト）
 * @param key PreferenceKey
 */
export async function getPrefrenceObject<T>(key: PreferenceKey) {
  const value = await Preferences.get({ key })
  return JSON.parse(value.value ?? '{}') as T
}

/**
 * 内部ストレージから取得する（文字列）
 * @param key PreferenceKey
 */
export async function getPrefrenceString(key: PreferenceKey) {
  return (await Preferences.get({ key })).value as string
}

/**
 * 内部ストレージから削除する
 * @param key PreferenceKey
 */
export async function removePrefernce(key: PreferenceKey) {
  return Preferences.remove({ key })
}
