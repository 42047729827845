export const constants = {
  validation: {
    account: {
      displayName: {
        maxLength: 15,
      },
      accountId: {
        word: new RegExp(/^[a-zA-Z0-9_]*$/),
        maxLength: 20,
      },
      introduction: {
        maxLength: 1000,
      },
    },
  },
  errorCodes: {
    /**
     * PrepaidPoint
     */
    INSUFFICIENT_PREPAID_POINT_BALANCE: 'INSUFFICIENT_PREPAID_POINT_BALANCE',
    INVALID_PREPAID_POINT_EXCHANGE_RATE: 'INVALID_PREPAID_POINT_EXCHANGE_RATE',
    /** メンバーシップに加入する必要があります */
    NEED_PATRON: 'NEED_PATRON',
    /** カードの有効期限が切れています */
    EXPIRED_CARD: 'EXPIRED_CARD',
    /** 3Dセキュア認証が必要です */
    REQUIRE_3D_SECURE: '3D_SECURE_REQUIRED',
    /** 何かしらの決済エラーです */
    PAYMENT_ERROR: 'STRIPE_CARD_ERROR',
  },
  storageKeys: {
    NAVIGATION_HISTORY: 'NAVIGATION_HISTORY',
    COMPLETED_GACHAS: 'COMPLETED_GACHAS',
    EVENT_MONTH: 'EVENT_MONTH',
  },
}
