import { ApolloError } from '@apollo/client'
import { joiResolver } from '@hookform/resolvers/joi/dist/joi.js'
import { TOptions } from 'i18next'
import type { SchemaMap } from 'joi'
import Joi from 'joi'
import get from 'lodash/get'
import { useEffect } from 'react'
import {
  FieldError,
  FieldErrors,
  FieldValues,
  Path,
  useForm as useReactHookForm,
  UseFormProps as UseReactHookFormProps,
  UseFormSetError,
} from 'react-hook-form'

import { messageRef, useMessage, useTranslation } from '@/core'

import { getLongLanguageCode, i18n } from '../i18n'
import { en, ja } from './message'

export * from './Form'
export type { NestedValue, SubmitHandler } from 'react-hook-form'

export type UseFormProps<T extends FieldValues = FieldValues> =
  UseReactHookFormProps<T> & {
    schema: (o: typeof Joi) => SchemaMap<T>
  }

export const useForm = <TFieldValues extends FieldValues>({
  schema,
  ...props
}: UseFormProps<TFieldValues>) => {
  const { showError } = useMessage()
  const { t } = useTranslation('common')

  const useFormReturn = useReactHookForm<TFieldValues>({
    //@ts-ignore
    resolver: joiResolver(makeSchema(schema(Joi)), {
      /**
       * @description
       * 言語設定がjaの場合日本語、それ以外は英語
       */
      messages: getLongLanguageCode(i18n.language) === 'en-US' ? en : ja,
      abortEarly: false,
      presence: 'required',
    }),
    mode: 'onChange',
    reValidateMode: 'onChange',
    ...props,
  })

  const { formState } = useFormReturn

  //submit時にエラーがあった場合、スナックバーを表示する
  useEffect(() => {
    if (!(formState.isSubmitted && !formState.isValid)) return

    const keys = Object.keys(formState.errors ?? {})

    if (!keys.length) return

    const singleErrorMessage = getFirstMessage<TFieldValues>(formState.errors)

    showError(keys.length > 1 ? t('formError') : singleErrorMessage)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formState.submitCount])

  return useFormReturn
}

const getFirstMessage = <T extends FieldValues>(
  error: { [key in string]: FieldErrors<T> } | FieldErrors<T>
): string => {
  const [key] = Object.keys(error)
  if (!key) {
    return ''
  }
  if ('message' in error && typeof error?.message === 'string') {
    return error?.message
  }
  return getFirstMessage(get(error, key) as FieldErrors<T>)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const makeSchema = <TSchema = any, T = TSchema>(
  schema: SchemaMap<T>
) => {
  return Joi.object<TSchema, false, T>(schema)
}

export const tLabel = (key: string, options?: TOptions) => {
  return i18n.t(key, { ns: 'label', ...options })
}

export const tMessage = (key: string, options?: TOptions) => {
  return i18n.t(key, { ns: 'message', ...options })
}

/**
 * @description handle set errors to error inputs
 * @param {ApolloError|Error} error
 * @param {UseFormSetError} setError
 * @param showError
 */
export const handleError = <T extends FieldValues>(
  error: ApolloError | Error,
  setError: UseFormSetError<T>,
  showError: (e: Error | string) => number
): void => {
  if (!('graphQLErrors' in error)) {
    showError(error)
    return
  }
  const errors: { name: Path<T>; error: FieldError }[] =
    error.graphQLErrors?.[0]?.extensions?.exception?.details?.map(
      (errorDetail: {
        path: Path<T>[]
        type: FieldError['type']
        message: FieldError['message']
      }) => ({
        name: errorDetail.path?.[0],
        error: {
          type: errorDetail.type,
          message: errorDetail.message,
        },
      })
    ) ?? []
  // show error other
  if (!errors.length) {
    showError(error)
    return
  }
  // show validate input error
  errors.map(({ name, error }) => setError(name, error))
  // show other error from server
  if (!errors.length) {
    messageRef.current?.showError(error)
  }
}

export const formRegex = {
  email:
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/,
}
