import common from './common.json'
import artistSideMenu from './layouts/artistSideMenu.json'
import agora from './lib/agora.json'
import ethers from './lib/ethers.json'
import ethRpc from './lib/ethRpc.json'
import firebase from './lib/firebase.json'
import firebaseCapacitorAuth from './lib/firebaseCapacitorAuth.json'
import consecutiveLoginBonus from './organisms/consecutiveLoginBonus.json'
import gps from './organisms/gps.json'
import loginBonus from './organisms/loginBonus.json'
import loginModal from './organisms/loginModal.json'
import membershipUserInfo from './organisms/membershipUserInfo.json'
import metamaskWalletConnectInfoModal from './organisms/metamaskWalletConnectInfoModal.json'
import requestPermission from './organisms/requestPermission.json'
import accountSettings from './templates/accountSettings.json'
import artistCollections from './templates/artistCollections.json'
import artistEvents from './templates/artistEvents.json'
import artistGachas from './templates/artistGachas.json'
import artistGallery from './templates/artistGallery.json'
import artistMembership from './templates/artistMembership.json'
import artistMembershipCompletion from './templates/artistMembershipCompletion.json'
import artistMembershipPaymentEdit from './templates/artistMembershipPaymentEdit.json'
import artistMembershipPurchaseConfirmation from './templates/artistMembershipPurchaseConfirmation.json'
import artistMembershipPurchaseProceed from './templates/artistMembershipPurchaseProceed.json'
import artistMissions from './templates/artistMissions.json'
import artistOfficialInformation from './templates/artistOfficialInformation.json'
import artistPosts from './templates/artistPosts.json'
import artistPostVideos from './templates/artistPostVideos.json'
import artistRanking from './templates/artistRanking.json'
import artistSearch from './templates/artistSearch.json'
import artistStore from './templates/artistStore.json'
import artistThreads from './templates/artistThreads.json'
import artistTop from './templates/artistTop.json'
import artistTrade from './templates/artistTrade.json'
import autoFollowSettings from './templates/autoFollowSettings.json'
import bankCreate from './templates/bankCreate.json'
import campaignDetail from './templates/campaignDetail.json'
import collectionDetail from './templates/collectionDetail.json'
import collectionItemDetail from './templates/collectionItemDetail.json'
import consoleAccountSetting from './templates/consoleAccountSetting.json'
import consoleBankEdit from './templates/consoleBankEdit.json'
import consoleBusinessEdit from './templates/consoleBusinessEdit.json'
import consoleCampaignBannerSetting from './templates/consoleCampaignBannerSetting.json'
import consoleCampaignDetail from './templates/consoleCampaignDetail.json'
import consoleCampaigns from './templates/consoleCampaigns.json'
import consoleCollectionCreate from './templates/consoleCollectionCreate.json'
import consoleCollectionDetail from './templates/consoleCollectionDetail.json'
import consoleCollectionEdit from './templates/consoleCollectionEdit.json'
import consoleCollections from './templates/consoleCollections.json'
import consoleData from './templates/consoleData.json'
import consoleDeliveryManagement from './templates/consoleDeliveryManagement.json'
import consoleEventCollectionReceiveTimeEdit from './templates/consoleEventCollectionReceiveTimeEdit.json'
import consoleEventCreate from './templates/consoleEventCreate.json'
import consoleEventDetail from './templates/consoleEventDetail.json'
import consoleEventEdit from './templates/consoleEventEdit.json'
import consoleEvents from './templates/consoleEvents.json'
import consoleHome from './templates/consoleHome.json'
import consoleItemCreate from './templates/consoleItemCreate.json'
import consoleItemDetail from './templates/consoleItemDetail.json'
import consoleItemEdit from './templates/consoleItemEdit.json'
import consoleItemInformation from './templates/consoleItemInformation.json'
import consoleItemInformationLimitUser from './templates/consoleItemInformationLimitUser.json'
import consoleItemOrders from './templates/consoleItemOrders.json'
import consoleItems from './templates/consoleItems.json'
import consoleLiveDetail from './templates/consoleLiveDetail.json'
import consoleLiveFinish from './templates/consoleLiveFinish.json'
import consoleLiveSetting from './templates/consoleLiveSetting.json'
import consoleLotteryCsvUpload from './templates/consoleLotteryCsvUpload.json'
import consoleLotteryManagementDetail from './templates/consoleLotteryManagementDetail.json'
import consoleLotteryManagements from './templates/consoleLotteryManagements.json'
import consoleMemberShip from './templates/consoleMemberShip.json'
import consoleMembershipCreate from './templates/consoleMembershipCreate.json'
import consoleMembershipDetail from './templates/consoleMembershipDetail.json'
import consoleMembershipEdit from './templates/consoleMembershipEdit.json'
import consoleMembershipExplain from './templates/consoleMembershipExplain.json'
import consoleMissionCreate from './templates/consoleMissionCreate.json'
import consoleMissionEdit from './templates/consoleMissionEdit.json'
import consoleMissions from './templates/consoleMissions.json'
import consoleMyProfileEdit from './templates/consoleMyProfileEdit.json'
import consoleOfficialInformationCreate from './templates/consoleOfficialInformationCreate.json'
import consoleOfficialInformationEdit from './templates/consoleOfficialInformationEdit.json'
import consoleOfficialInformations from './templates/consoleOfficialInformations.json'
import consoleOrderDetail from './templates/consoleOrderDetail.json'
import consoleOrders from './templates/consoleOrders.json'
import consolePostCategories from './templates/consolePostCategories.json'
import consolePostCreate from './templates/consolePostCreate.json'
import consolePostDetail from './templates/consolePostDetail.json'
import consolePostEdit from './templates/consolePostEdit.json'
import consolePosts from './templates/consolePosts.json'
import consolePreSaleCreate from './templates/consolePreSaleCreate.json'
import consolePreSaleDetail from './templates/consolePreSaleDetail.json'
import consolePreSaleEdit from './templates/consolePreSaleEdit.json'
import consoleQuantityManagement from './templates/consoleQuantityManagement.json'
import consoleRevenue from './templates/consoleRevenue.json'
import consoleTicketReceiveDetail from './templates/consoleTicketReceiveDetail.json'
import consoleTicketReceives from './templates/consoleTicketReceives.json'
import consoleUnpublishedPosts from './templates/consoleUnpublishedPosts.json'
import consoleUserDetail from './templates/consoleUserDetail.json'
import consoleUserOrders from './templates/consoleUserOrders.json'
import consoleUserOwnedItemDetail from './templates/consoleUserOwnedItemDetail.json'
import consoleUserOwnedItems from './templates/consoleUserOwnedItems.json'
import consoleUsers from './templates/consoleUsers.json'
import customProfileEditAvatar from './templates/customProfileEditAvatar.json'
import customProfileEditWallPaper from './templates/customProfileEditWallPaper.json'
import deactivation from './templates/deactivation.json'
import deactivationCompletion from './templates/deactivationCompletion.json'
import deactivationConfirm from './templates/deactivationConfirm.json'
import deliveryInfoSetting from './templates/deliveryInfoSetting.json'
import deliveryManagement from './templates/deliveryManagement.json'
import emailAddressSettings from './templates/emailAddressSettings.json'
import emailNotificationSettings from './templates/emailNotificationSettings.json'
import eventCollectionDetail from './templates/eventCollectionDetail.json'
import eventDetail from './templates/eventDetail.json'
import events from './templates/events.json'
import firstComeFirstServedComplete from './templates/firstComeFirstServedComplete.json'
import firstComeFirstServedConfirmation from './templates/firstComeFirstServedConfirmation.json'
import firstComeFirstServedDetail from './templates/firstComeFirstServedDetail.json'
import forbidden from './templates/forbidden.json'
import forceUpdate from './templates/forceUpdate.json'
import gachaDetail from './templates/gachaDetail.json'
import gachaItemList from './templates/gachaItemList.json'
import gachaResult from './templates/gachaResult.json'
import globalArtist from './templates/globalArtist.json'
import globalCollections from './templates/globalCollections.json'
import globalEvents from './templates/globalEvents.json'
import globalHome from './templates/globalHome.json'
import integrations from './templates/integrations.json'
import itemComment from './templates/itemComment.json'
import itemInformation from './templates/itemInformation.json'
import itemMissions from './templates/itemMissions.json'
import itemOrders from './templates/itemOrders.json'
import languageSetting from './templates/languageSetting.json'
import liveDetail from './templates/liveDetail.json'
import login from './templates/login.json'
import lotteryApplicationDetail from './templates/lotteryApplicationDetail.json'
import lotteryApplicationDetailCancelCompletion from './templates/lotteryApplicationDetailCancelCompletion.json'
import lotteryApplicationDetailPaymentConfirmation from './templates/lotteryApplicationDetailPaymentConfirmation.json'
import lotteryApplications from './templates/lotteryApplications.json'
import lotteryCompletion from './templates/lotteryCompletion.json'
import lotteryConfirmation from './templates/lotteryConfirmation.json'
import lotteryDeliveryInfo from './templates/lotteryDeliveryInfo.json'
import lotteryDetail from './templates/lotteryDetail.json'
import lotteryUserInfo from './templates/lotteryUserInfo.json'
import mailAuth from './templates/mailAuth.json'
import mailAuthArtist from './templates/mailAuthArtist.json'
import mailRegister from './templates/mailRegister.json'
import management from './templates/management.json'
import membership from './templates/membership.json'
import missionAccomplished from './templates/missionAccomplished.json'
import missionDetail from './templates/missionDetail.json'
import myOwnedTicketDetail from './templates/myOwnedTicketDetail.json'
import myOwnedTickets from './templates/myOwnedTickets.json'
import myOwnedTicketsEventSelection from './templates/myOwnedTicketsEventSelection.json'
import myProfile from './templates/myProfile.json'
import myProfileEdit from './templates/myProfileEdit.json'
import myRanking from './templates/myRanking.json'
import myRequestedTradeDetail from './templates/myRequestedTradeDetail.json'
import myTickets from './templates/myTickets.json'
import myTrade from './templates/myTrade.json'
import myTradeDetail from './templates/myTradeDetail.json'
import newStoreItems from './templates/newStoreItems.json'
import nftItemDetail from './templates/nftItemDetail.json'
import nftItems from './templates/nftItems.json'
import notFound from './templates/notFound.json'
import notFoundWeb from './templates/notFoundWeb.json'
import notificationDetail from './templates/notificationDetail.json'
import notifications from './templates/notifications.json'
import officialInformation from './templates/officialInformation.json'
import officialInformationDetail from './templates/officialInformationDetail.json'
import ownedItemDetail from './templates/ownedItemDetail.json'
import ownedItems from './templates/ownedItems.json'
import passwordReset from './templates/passwordReset.json'
import passwordResetSend from './templates/passwordResetSend.json'
import passwordSettings from './templates/passwordSettings.json'
import paymentCreate from './templates/paymentCreate.json'
import paymentDetail from './templates/paymentDetail.json'
import paymentError from './templates/paymentError.json'
import paymentStatusDetail from './templates/paymentStatusDetail.json'
import paymentStatuses from './templates/paymentStatuses.json'
import personalInfoSettings from './templates/personalInfoSettings.json'
import postDetail from './templates/postDetail.json'
import prepaidPointCharge from './templates/prepaidPointCharge.json'
import prepaidPointOrders from './templates/prepaidPointOrders.json'
import privacySettings from './templates/privacySettings.json'
import profileInputArtist from './templates/profileInputArtist.json'
import pushNotificationSettings from './templates/pushNotificationSettings.json'
import qrCodeGetGachaTicket from './templates/qrCodeGetGachaTicket.json'
import qrCodeGetItem from './templates/qrCodeGetItem.json'
import qrCodeGetItemMulti from './templates/qrCodeGetItemMulti.json'
import recentViewedItems from './templates/recentViewedItems.json'
import recommendCollections from './templates/recommendCollections.json'
import resaleApplicationCreate from './templates/resaleApplicationCreate.json'
import resaleApplicationCreateCompletion from './templates/resaleApplicationCreateCompletion.json'
import resaleApplicationCreateConfirmation from './templates/resaleApplicationCreateConfirmation.json'
import resaleApplicationCreatePrecaution from './templates/resaleApplicationCreatePrecaution.json'
import resaleApplicationDetail from './templates/resaleApplicationDetail.json'
import resaleCreate from './templates/resaleCreate.json'
import resaleCreateCompletion from './templates/resaleCreateCompletion.json'
import resaleCreateConfirmation from './templates/resaleCreateConfirmation.json'
import resaleCreatePrecaution from './templates/resaleCreatePrecaution.json'
import resaleDetail from './templates/resaleDetail.json'
import resaleManagement from './templates/resaleManagement.json'
import resaleRaffleDetail from './templates/resaleRaffleDetail.json'
import resaleRaffleDetailCancelCompletion from './templates/resaleRaffleDetailCancelCompletion.json'
import resaleRaffleRepaymentCompletion from './templates/resaleRaffleRepaymentCompletion.json'
import resaleRaffleRepaymentConfirmation from './templates/resaleRaffleRepaymentConfirmation.json'
import serialNumberSelect from './templates/serialNumberSelect.json'
import serverError from './templates/serverError.json'
import settings from './templates/settings.json'
import sideMenu from './templates/sideMenu.json'
import signUp from './templates/signUp.json'
import signUpArtist from './templates/signUpArtist.json'
import smsVerification from './templates/smsVerification.json'
import storeCartDetail from './templates/storeCartDetail.json'
import storeCartList from './templates/storeCartList.json'
import storeCollectionDetail from './templates/storeCollectionDetail.json'
import storeCollectionsSelect from './templates/storeCollectionsSelect.json'
import storeDeliveryInfo from './templates/storeDeliveryInfo.json'
import storeItemDetail from './templates/storeItemDetail.json'
import storeItemPurchaseComplete from './templates/storeItemPurchaseComplete.json'
import storeItemPurchaseConfirmation from './templates/storeItemPurchaseConfirmation.json'
import storeItemPurchaseProceed from './templates/storeItemPurchaseProceed.json'
import storePurchaseOrderDetail from './templates/storePurchaseOrderDetail.json'
import storePurchaseOrders from './templates/storePurchaseOrders.json'
import storeRequestProvidableItemSearch from './templates/storeRequestProvidableItemSearch.json'
import storeUserInfo from './templates/storeUserInfo.json'
import threadCreate from './templates/threadCreate.json'
import threadDetail from './templates/threadDetail.json'
import ticketCharge from './templates/ticketCharge.json'
import ticketOrders from './templates/ticketOrders.json'
import ticketShareConfirm from './templates/ticketShareConfirm.json'
import ticketShareCopy from './templates/ticketShareCopy.json'
import ticketShareSelect from './templates/ticketShareSelect.json'
import top from './templates/top.json'
import tradeCollectionSelect from './templates/tradeCollectionSelect.json'
import tradeItemDetailSelect from './templates/tradeItemDetailSelect.json'
import tradeRequestConfirm from './templates/tradeRequestConfirm.json'
import tradeRequestProvidableItemSearch from './templates/tradeRequestProvidableItemSearch.json'
import tradeRequestProvidableItemSelect from './templates/tradeRequestProvidableItemSelect.json'
import tradeRequestUserSelect from './templates/tradeRequestUserSelect.json'
import unregisteredPasswordSettings from './templates/unregisteredPasswordSettings.json'
import userItemDetail from './templates/userItemDetail.json'
import userOwnedItems from './templates/userOwnedItems.json'
import userProfile from './templates/userProfile.json'
import userTradeDetail from './templates/userTradeDetail.json'
import wallet from './templates/wallet.json'
import walletHistory from './templates/walletHistory.json'
import walletPasswordSettings from './templates/walletPasswordSettings.json'

export {
  accountSettings,
  agora,
  artistCollections,
  artistEvents,
  artistGachas,
  artistGallery,
  artistMembership,
  artistMembershipCompletion,
  artistMembershipPaymentEdit,
  artistMembershipPurchaseConfirmation,
  artistMembershipPurchaseProceed,
  artistMissions,
  artistOfficialInformation,
  artistPosts,
  artistPostVideos,
  artistRanking,
  artistSearch,
  artistSideMenu,
  artistStore,
  artistThreads,
  artistTop,
  artistTrade,
  autoFollowSettings,
  bankCreate,
  campaignDetail,
  collectionDetail,
  collectionItemDetail,
  common,
  consecutiveLoginBonus,
  consoleAccountSetting,
  consoleBankEdit,
  consoleBusinessEdit,
  consoleCampaignBannerSetting,
  consoleCampaignDetail,
  consoleCampaigns,
  consoleCollectionCreate,
  consoleCollectionDetail,
  consoleCollectionEdit,
  consoleCollections,
  consoleData,
  consoleDeliveryManagement,
  consoleEventCollectionReceiveTimeEdit,
  consoleEventCreate,
  consoleEventDetail,
  consoleEventEdit,
  consoleEvents,
  consoleHome,
  consoleItemCreate,
  consoleItemDetail,
  consoleItemEdit,
  consoleItemInformation,
  consoleItemInformationLimitUser,
  consoleItemOrders,
  consoleItems,
  consoleLiveDetail,
  consoleLiveFinish,
  consoleLiveSetting,
  consoleLotteryCsvUpload,
  consoleLotteryManagementDetail,
  consoleLotteryManagements,
  consoleMemberShip,
  consoleMembershipCreate,
  consoleMembershipDetail,
  consoleMembershipEdit,
  consoleMembershipExplain,
  consoleMissionCreate,
  consoleMissionEdit,
  consoleMissions,
  consoleMyProfileEdit,
  consoleOfficialInformationCreate,
  consoleOfficialInformationEdit,
  consoleOfficialInformations,
  consoleOrderDetail,
  consoleOrders,
  consolePostCategories,
  consolePostCreate,
  consolePostDetail,
  consolePostEdit,
  consolePosts,
  consolePreSaleCreate,
  consolePreSaleDetail,
  consolePreSaleEdit,
  consoleQuantityManagement,
  consoleRevenue,
  consoleTicketReceiveDetail,
  consoleTicketReceives,
  consoleUnpublishedPosts,
  consoleUserDetail,
  consoleUserOrders,
  consoleUserOwnedItemDetail,
  consoleUserOwnedItems,
  consoleUsers,
  customProfileEditAvatar,
  customProfileEditWallPaper,
  deactivation,
  deactivationCompletion,
  deactivationConfirm,
  deliveryInfoSetting,
  deliveryManagement,
  emailAddressSettings,
  emailNotificationSettings,
  ethers,
  ethRpc,
  eventCollectionDetail,
  eventDetail,
  events,
  firebase,
  firebaseCapacitorAuth,
  firstComeFirstServedComplete,
  firstComeFirstServedConfirmation,
  firstComeFirstServedDetail,
  forbidden,
  forceUpdate,
  gachaDetail,
  gachaItemList,
  gachaResult,
  globalArtist,
  globalCollections,
  globalEvents,
  globalHome,
  gps,
  integrations,
  itemComment,
  itemInformation,
  itemMissions,
  itemOrders,
  languageSetting,
  liveDetail,
  login,
  loginBonus,
  loginModal,
  lotteryApplicationDetail,
  lotteryApplicationDetailCancelCompletion,
  lotteryApplicationDetailPaymentConfirmation,
  lotteryApplications,
  lotteryCompletion,
  lotteryConfirmation,
  lotteryDeliveryInfo,
  lotteryDetail,
  lotteryUserInfo,
  mailAuth,
  mailAuthArtist,
  mailRegister,
  management,
  membership,
  membershipUserInfo,
  metamaskWalletConnectInfoModal,
  missionAccomplished,
  missionDetail,
  myOwnedTicketDetail,
  myOwnedTickets,
  myOwnedTicketsEventSelection,
  myProfile,
  myProfileEdit,
  myRanking,
  myRequestedTradeDetail,
  myTickets,
  myTrade,
  myTradeDetail,
  newStoreItems,
  nftItemDetail,
  nftItems,
  notFound,
  notFoundWeb,
  notificationDetail,
  notifications,
  officialInformation,
  officialInformationDetail,
  ownedItemDetail,
  ownedItems,
  passwordReset,
  passwordResetSend,
  passwordSettings,
  paymentCreate,
  paymentDetail,
  paymentError,
  paymentStatusDetail,
  paymentStatuses,
  personalInfoSettings,
  postDetail,
  prepaidPointCharge,
  prepaidPointOrders,
  privacySettings,
  profileInputArtist,
  pushNotificationSettings,
  qrCodeGetGachaTicket,
  qrCodeGetItem,
  qrCodeGetItemMulti,
  recentViewedItems,
  recommendCollections,
  requestPermission,
  resaleApplicationCreate,
  resaleApplicationCreateCompletion,
  resaleApplicationCreateConfirmation,
  resaleApplicationCreatePrecaution,
  resaleApplicationDetail,
  resaleCreate,
  resaleCreateCompletion,
  resaleCreateConfirmation,
  resaleCreatePrecaution,
  resaleDetail,
  resaleManagement,
  resaleRaffleDetail,
  resaleRaffleDetailCancelCompletion,
  resaleRaffleRepaymentCompletion,
  resaleRaffleRepaymentConfirmation,
  serialNumberSelect,
  serverError,
  settings,
  sideMenu,
  signUp,
  signUpArtist,
  smsVerification,
  storeCartDetail,
  storeCartList,
  storeCollectionDetail,
  storeCollectionsSelect,
  storeDeliveryInfo,
  storeItemDetail,
  storeItemPurchaseComplete,
  storeItemPurchaseConfirmation,
  storeItemPurchaseProceed,
  storePurchaseOrderDetail,
  storePurchaseOrders,
  storeRequestProvidableItemSearch,
  storeUserInfo,
  threadCreate,
  threadDetail,
  ticketCharge,
  ticketOrders,
  ticketShareConfirm,
  ticketShareCopy,
  ticketShareSelect,
  top,
  tradeCollectionSelect,
  tradeItemDetailSelect,
  tradeRequestConfirm,
  tradeRequestProvidableItemSearch,
  tradeRequestProvidableItemSelect,
  tradeRequestUserSelect,
  unregisteredPasswordSettings,
  userItemDetail,
  userOwnedItems,
  userProfile,
  userTradeDetail,
  wallet,
  walletHistory,
  walletPasswordSettings,
}
