"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const appConfig = {
    theme: {
        dark: false,
    },
    appName: 'あばれる君のアドベンチャークラブ',
    organizationId: 'awBRwVLjlfPepuDoCaOx',
    version: '1.1',
    ionicAppId: '',
    applicationKey: 'abarerukun',
    applicationType: 'standalone',
    artistId: '34hywLE4yEOwV2lcmYPkoFvQfi12',
    languages: ['ja', 'en'],
    ios: {
        bundleId: '',
        appId: '',
    },
    android: {
        bundleId: '',
    },
    platform: ['web'],
    custom: {
        modules: {
            tutorial: false,
            trade: false,
            membership: true,
            thread: true,
            prepaidPoint: null,
            gacha: false,
            nft: false,
            liveStream: false,
            item: true,
            store: true,
            campaign: false,
            mission: false,
            resale: false,
            preSale: true,
            event: true,
            cart: true,
            pickup: false,
            eprint: false,
            konbiniPayment: true,
        },
        tutorials: [
            'images/tutorial/Tutorial02.png',
            'images/tutorial/Tutorial04.png',
        ],
        globalTabs: [],
        artistTabs: ['home', 'posts', 'store', 'events', 'myProfile'],
    },
    deeplinkUrl: 'https://abarerukun.utoniq.com',
    helpUrl: 'https://help.utoniq.com/ja/collections/2299305',
    storagePath: 'abarerukun',
    dynamicLinkPrefix: 'abarerukun',
    deeplinkProd: 'abarerukun.utoniq.com',
};
exports.default = appConfig;
