import { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { constants } from '@/config'

const useNavigationHistory = () => {
  const location = useLocation()
  const [history, setHistory] = useState<string[]>(() => {
    const storedHistory = sessionStorage.getItem(
      constants.storageKeys.NAVIGATION_HISTORY
    )
    return storedHistory ? JSON.parse(storedHistory) : []
  })

  useEffect(() => {
    const updateHistory = (newPath: string) => {
      if (history.length > 0 && history[0] === newPath) {
        return // 直近のURLと同じ場合は更新しない
      }

      const newHistory = [newPath, ...history].slice(0, 10)
      sessionStorage.setItem(
        constants.storageKeys.NAVIGATION_HISTORY,
        JSON.stringify(newHistory)
      )
      setHistory(newHistory)
    }

    updateHistory(location.pathname)
  }, [location.pathname])

  return history
}

export default useNavigationHistory
